<template>
  <main class="pb-10">
    <UiFixedHeader always-visible>
      <template v-if="compilation?.title">
        {{ compilation?.title }}
      </template>
      <div v-else class="h-6 w-60 animate-pulse rounded-md bg-secondary" />
    </UiFixedHeader>
    <div class="sticky top-13 z-20 bg-primary md:top-0 lg:pb-4">
      <UiContainer class="mb-3 hidden items-center md:flex">
        <UiTitle v-if="compilation?.title" severity="h1" class="!text-base md:!text-3xl">
          {{ compilation?.title }}
        </UiTitle>
        <div v-else class="h-9 w-80 animate-pulse rounded-md bg-secondary" />
      </UiContainer>
      <FilterSearch :page-size="PAGE_SIZE" @change="onFiltersChange" />
    </div>
    <UiContainer>
      <section class="relative flex w-full flex-col">
        <CompilationWidget
          :value="compilation"
          :loading="!compilationInitialized"
          type="masonry"
          class="-mx-4 lg:-mx-7"
        />
        <UiEternalLoading
          v-if="!allItemsLoaded"
          :handler="loadMoreItems"
          :all-loaded="allItemsLoaded"
        />
        <UiScrollUpButton class="z-210 !sticky bottom-10 ml-auto mr-0" />
      </section>
    </UiContainer>
  </main>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { onBeforeMount } from 'vue'
import { useRoute, onBeforeRouteUpdate } from 'vue-router'
import { CompilationWidget } from '@/3_widgets/Compilation'
import { FilterSearch } from '@/4_features/Search'
import { useCompilation } from '@/5_entities/Compilation'
import type { BaseSearchFilters } from '@/6_shared/model'
import {
  UiContainer,
  UiEternalLoading,
  UiTitle,
  UiScrollUpButton,
  UiFixedHeader
} from '@/6_shared/ui'

const route = useRoute()
const PAGE_SIZE = 24
let filters: BaseSearchFilters = {
  pagination: { pageSize: PAGE_SIZE, currentPage: 1 },
  filter: {}
}
const onFiltersChange = (newFilters: BaseSearchFilters) => {
  filters = newFilters
  loadCompilation(route.params.slug as string)
}
const { getCompilation, loadNextPage } = useCompilation()
const { compilation, compilationInitialized, allItemsLoaded } = storeToRefs(useCompilation())

const loadCompilation = (slug: string) => getCompilation(slug, filters)
const loadMoreItems = () => loadNextPage(filters)

onBeforeMount(() => loadCompilation(route.params.slug as string))

onBeforeRouteUpdate(({ params: { slug: toSlug } }, { params: { slug: fromSlug } }) => {
  if (fromSlug !== toSlug) {
    loadCompilation(toSlug as string)
  }
})
</script>

<style scoped>
.calendar-block::after {
  background: linear-gradient(
    180deg,
    var(--colors-gradient-fivefold-first),
    var(--colors-gradient-fivefold-second) 22%,
    var(--colors-gradient-fivefold-third) 100%
  );
}
</style>
